<template>
  <div>
    <div
      v-permission="'questionnaire_correctors.create'"
      class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew">
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <Grid :key="key"
          :service="service"
          route_name="questionnaire_correctors">
    </Grid>
  </div>
</template>

<script>
import QuestionnaireCorrectorService from '@/services/api/QuestionnaireCorrectorService'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/questionnaire_correctors/create')
    }
  },
  beforeMount() {
    this.service = QuestionnaireCorrectorService.build(this.$vs)
  }
}
</script>
